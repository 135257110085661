<template>
  <div class="specializations">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      class="specialization-list"
      @load-more-items="loadMoreItems"
    >
      <div class="specialization-list-inner">
        <div class="crm-container crm-container-title">
          <span class="crm-title">
            Специализации
          </span>
        </div>
        <div class="d-flex flex-column">
          <div class="crm-container crm-container-search">
            <b-input-group
              class="bg-white border rounded"
              :class="[inputFocused ? 'border-primary' : '']"
            >
              <b-input
                v-model="query"
                placeholder="Введите название специализации"
                class="border-0"
                @keyup.native.enter="specializationsFetch"
                @focus="inputFocused = true"
                @blur="inputFocused = false"
              />
              <b-button
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
                class="rounded-0 border-0"
                @click="specializationsFetch"
              >
                <b-icon icon="search" />
              </b-button>
            </b-input-group>

            <b-button
              v-if="checkFeatureAccess({ name: 'Новая специализация', url: '/specializations' })"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="text-nowrap ml-3"
              @click="openEditModal"
            >
              Новая специализация
            </b-button>
          </div>
          <div class="ml-5">
            <b-form-checkbox
              v-model="isDeleted"
              class="crm-container"
              :unchecked-value="false"
            >
              <span class="crm-form-checkbox-text">
                Показать удалённые специализации
              </span>
            </b-form-checkbox>
          </div>
        </div>
        <div
          v-if="!totalCount && isAllItemsLoaded"
          class="crm-container"
        >
          <span
            class="page-subtitle"
          >
            Ничего не найдено
          </span>
        </div>

        <template v-else>
          <div class="crm-container">
            <div class="specialization-info-list">
              <specialization-info
                v-for="(specialization, index) in filteredSpecializations"
                :key="index"
                :specialization="specialization"
                class="specialization-info-item"
              />
            </div>
          </div>
        </template>
      </div>
    </async-list>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import AsyncList from '@/components/AsyncList';
import SpecializationInfo from '@/components/Specializations/SpecializationInfo';

const LIMIT = 15;

export default {
  name: 'Specializations',
  page: {
    title: 'CRM Doctis - Специализации',
  },
  components: {
    AsyncList,
    SpecializationInfo,
  },
  mixins: [mixinRoles],
  data() {
    return {
      query: '',
      isAllItemsLoaded: false,
      isLoading: false,
      inputFocused: false,
      limit: LIMIT,
      isDeleted: false,
    };
  },
  computed: {
    specializations() {
      return this.$store.state.Specializations.specializations;
    },
    filteredSpecializations() {
      return this.specializations.filter((s) => s.isDeleted === this.isDeleted);
    },
    totalCount() {
      return this.specializations.length;
    },
  },
  created() {
    Bus.$on('specializations:update', this.updateSpecializations);
  },
  beforeDestroy() {
    this.$store.commit(this.$types.SPECIALIZATIONS_SET, []);
    Bus.$off('specializations:update', this.updateSpecializations);
  },
  methods: {
    openEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SpecializationEditModal',
      });
    },
    async specializationsFetch() {
      if (this.isLoading) return;

      this.isAllItemsLoaded = false;
      this.isLoading = true;
      const prevQuery = this.query;

      try {
        this.$store.commit(this.$types.SPECIALIZATIONS_SET, []);
        await this.$store.dispatch(this.$types.SPECIALIZATIONS_FETCH, prevQuery);
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
        this.isAllItemsLoaded = true;

        if (this.query !== prevQuery) this.specializationsFetch();
      }
    },
    loadMoreItems() {
      this.specializationsFetch();
    },
    updateSpecializations() {
      this.specializationsFetch();
    },
  },
};

</script>

<style lang="scss" scoped>
.specializations {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
}
::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}
.crm-container-title{
    margin-bottom: 40px;
}
.crm-container-search {
  margin-bottom: 30px;
}

.show-specialization {

}
.crm-form-checkbox-text {
  font-size: 16px;
  line-height: 24px;
}

.specialization-list {
  height: 100vh;
}

.specialization-list-inner {
  padding: 30px 0;
}

.specialization-info-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  flex: 1;
}

.specialization-info-item {
  margin: 10px;
  width: calc(25% - 20px);
  @media screen and (max-width: 1200px) {
    width: calc(33.3% - 20px);
  }
}

.crm-icon-cross {
  margin-right: 15px;
}
</style>
